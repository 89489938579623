import * as jsPDF from 'jspdf';
import * as HTMLtoPDF from 'html2pdf.js';

export class PrintUtils {

    public static print(title, reportData, tableHeaders, tableData) {
      return new Promise(
          (resolve, reject) => {

            let content = '<style>' + 'table tr:nth-child(2n){background-color: rgba(240,240,240,1)} ' +
            /*'table tr:nth-child(16){border-top: solid 1px #000000}' +*/
            'table tr td:last-child{border-right: solid 1px #000000 !important;}' +
            'table thead th:last-child{border-right: solid 1px #000000 !important;}' +
            '.total-table tr:first-child td{border-top: solid 1px #000000 !important;}' +
            /*table thead th:nth-child(1){width: 130px !important;}' +
            'table thead th:nth-child(5){width: 110px !important;}' +*/
            '</style>' +
            // '</head>' +
            // '<body>' +
            '<div class="print-header-content" style="width: 100%; padding: 0; margin: 0; float: left;">\n' +
            '    <div class="print-logo" style="margin: 0px 0; padding: 0; float: left;">\n' +
            '      <img src="./assets/images/kekeli2.png" alt="logo-kekeli" style="width: 60px;">\n' +
            '    </div>\n' +
            '    <div class="print-header-text" style="margin: 0; padding: 0; float: right;">\n' +
            '      <ul style="padding: 0; margin: 0 20px 0 0; float: left;">\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Tél :</b></li>\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;">22 22 21 21</li>\n' +
            '      </ul>\n' +
            '      <ul style="padding: 0; margin: 0 20px 0 0; float: left;">\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Site web :</b></li>\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;">kekeliedu.net</li>\n' +
            '      </ul>\n' +
            '      <ul style="padding: 0; margin: 0; float: left;">\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Email :</b></li>\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;">kekeliedu@gmail.com</li>\n' +
            '      </ul>\n' +
            '    </div>\n' +
            '  </div>' + '<div style="width: 100%; padding: 0; margin: 0;">' + '<h1 style="width: 100%; text-align: center; font-size: 1.6em;' +
            ' float: left; color: #000000; ' + 'font-weight: normal; margin: 10.5px 0; padding: 0;">' +
            '<b>'+ title +'</b></h1>'+
            '</div>';
      
          content = content + '<table class="total-table" style="border-collapse: collapse; width: 49%; margin: 8px 2% 30px 0; float: left;">';
          
          reportData.forEach(
            (line, index) => {
                content = content + '<tr>' + '<td style="width: 200px; border: solid 1px #000000; padding: 5px 8px; ' +
                'text-align: left; border-top: none; border-right: none; font-weight: 600; background-color: rgba(240, 240, 240, 1);">' +
                line.field + '</td>' + '<td style="background-color: #ffffff; border: solid 1px #000000; padding: 5px 8px; text-align: left;' +
                'border-top: none; border-right: none;">' + line.value + '</td>' + '</tr>';
            }
          ); 
      
          content = content + '</table>';
      
          content = content + '<table style="border-collapse: collapse; width: 97%; margin-top: 0px; float: left;">';
      
          let page = 0;
      
          content = content + '<tbody>';
          
          // body

          content = content + tableData;
          
          content = content + '</tbody>';
      
          content = content + '<thead>';
      
          content = content + '<tr>';

          // Headers 
          
          content = content + tableHeaders;
          
          content = content + '</tr>';
      
          content = content + '</thead>';  
      
          content = content + '</table>';
      
        //   HTMLtoPDF(content, {
        //     margin:       0.5,
        //     filename:     'rapport_' + Date.now() + '.pdf',
        //     image:        { type: 'jpeg', quality: 1 },
        //     html2canvas:  { },
        //     jsPDF:        { unit: 'cm', format: 'a4', orientation: 'landscape' }
        //   }).then(
        //     (res) => {
        //       resolve();
        //     }
        //   );
      
          var style = "<style>";
          style = style + "table {width: 100%;font: 17px Calibri;}";
          style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
          style = style + "padding: 2px 3px;text-align: center;}";
          style = style + "</style>";
      
          // CREATE A WINDOW OBJECT.
          var win = window.open('', '', 'height=700,width=700');
      
          win.document.write('<html><head>');
          win.document.write('<title>'+ title +'</title>');   // <title> FOR PDF HEADER.
          win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
          win.document.write('</head>');
          win.document.write('<body>');
          win.document.write(content);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
          win.document.write('</body></html>');
      
          win.document.close(); 	// CLOSE THE CURRENT WINDOW.
      
          win.print();  
          resolve();  // PRINT THE CONTENTS.
          }
      );
    }
  
  }
  