import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders} from '@angular/common/http';
import {GlobalVariable} from '../global';
import {Subject} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class TestService {
    stepSubject: Subject<any> = new Subject<any>();
    questionnairesSack: any;
    testResultsSack: any;

    constructor(private http: HttpClient) {
    }

    getAccountValidity(pseudo) {
        return this.http.get(GlobalVariable.BASE_USER_API_URL + 
            '/getAccountValidity.php', {
                params: {
                    pseudo: pseudo
                }
            }).pipe(
            map(
                ((response: HttpResponse<any>) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

    getMatieres(pseudo) {
        return this.http.get(GlobalVariable.BASE_USER_API_URL + 
            '/getMatieres.php', {
                params: {
                    pseudo: pseudo
                }
            }).pipe(
            map(
                ((response: HttpResponse<any>) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

    getChapitres(body) {
        return this.http.get(GlobalVariable.BASE_USER_API_URL + 
            '/getChapitres.php', {
                params: body
            }).pipe(
            map(
                ((response: HttpResponse<any>) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

    getQuestionnaires(body) {
        return this.http.get(GlobalVariable.BASE_USER_API_URL + 
            '/getQuestionnaires.php', {
                params: body
            }).pipe(
            map(
                ((response: any) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

    postTest(body) {
        return this.http.post(GlobalVariable.BASE_USER_API_URL + 
            '/postTest.php', body).pipe(
            map(
                ((response: any) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

    getOneLifeRemove() {
        return this.http.get(GlobalVariable.BASE_USER_API_URL + 
            '/removeOneLife.php' ).pipe(
            map(
                ((response: any) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

}    