import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/index';
import { RegisterService } from './register.service';

@Injectable()
export class RegisterFormDataResolver implements Resolve<any> {

    constructor(private registerService: RegisterService ) {
    }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.registerService.getRegisterFormData();
    }

}
