import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders} from '@angular/common/http';
import {GlobalVariable} from '../global';
import {Subject} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from '../shared/user/user.service';
import { User } from '../shared/user/model/user.model';


@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    stepSubject: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient, 
        private userService: UserService) {
    }

    getRegisterFormData() {
        return this.http.get(GlobalVariable.BASE_USER_API_URL + '/register_form_data.php').pipe(
            map(
                ((response: HttpResponse<any>) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

    registerUser(body) {
        return this.http.post(GlobalVariable.BASE_USER_API_URL +
             '/register_user.php', body).pipe(
            map(
                ((response: HttpResponse<any>) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

    setRegistringProfilePhoto(body) {
        return this.http.post(GlobalVariable.BASE_USER_API_URL +
             '/setRegistringProfilePhoto.php', body).pipe(
            map(
                ((response: HttpResponse<any>) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }

    firstLogin(body) {
        return this.http.post(GlobalVariable.BASE_USER_API_URL + '/firstLogin.php' ,
         body)
      .pipe(
          map(
              ((response: any) => {
                    const user = new User(response.pseudo, null, response.authToken, response.data);
                    window.localStorage.clear();
                    window.localStorage.setItem('currentUser', JSON.stringify(user));
                    this.userService.currentUser.next(user);
                    return user;
                  }
              )),
          catchError(
              (error: HttpErrorResponse) => {
                  return throwError({
                      status: error.status,
                      message: error.error.message
                  });
              }
          )
      );
    }

}