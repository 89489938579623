import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/index';
import { TestService } from './test.service';
import { UserService } from '../shared/user/user.service';

@Injectable()
export class MatieresResolver implements Resolve<any> {
    currentUser: any;
    constructor(private testService: TestService, private userServie: UserService) {
        this.currentUser = this.userServie.getCurrentUser();
    }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
               return this.testService.getMatieres(this.currentUser.pseudo);
        // return this.commandeDetailService.getOrder(route.params['id']);
    }

}
