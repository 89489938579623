import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import {GlobalVariable} from '../global';
import {HttpErrorResponse, HttpResponse, HttpClient} from '@angular/common/http';
import {Subject, Observable} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../shared/user/user.service';


@Injectable({
    providedIn: 'root'
})
export class RegisterValidator {
  currentUser: any;
  constructor( private http: HttpClient, private route: ActivatedRoute, private userService: UserService ) {
    this.currentUser = this.userService.getCurrentUser();
  }

  confirmPassword(field: AbstractControl): { passwordMismatch: boolean } {
    const fieldParent = field.parent;
    const fieldValue = field.value;
    if (fieldParent !== null && fieldParent !== undefined) {
      const formValue = fieldParent.value;
      if (formValue.mot_passe !== fieldValue) {
        return {'passwordMismatch': true};
      } else {
        return;
      }
    }
  }

  uniquePseudo(field: FormControl): Promise<any> | Observable<any> {
        return this.http.post(GlobalVariable.BASE_USER_API_URL + '/VALIDATORS/unique_pseudo.php', 
            {pseudo: field.value}).pipe(
                map(
                    ((response: any) => {
                            if (response) {
                                if (response.pseudo) {
                                    if (response.pseudo !== this.currentUser.pseudo) {
                                        return { pseudoIsUnique: true };
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return false;
                                }
                            } else {
                                return;
                            }
                        }
                    )),
                catchError(
                    (error: HttpErrorResponse) => {
                        return throwError({
                            status: error.status,
                            message: error.error.message
                        });
                    }
                )
            );

  }

  uniqueEmail(field: FormControl): Promise<any> | Observable<any> {
    return this.http.post(GlobalVariable.BASE_USER_API_URL + '/VALIDATORS/unique_email.php', 
        {email: field.value}).pipe(
            map(
                ((response: any) => {
                        if (response) {
                            if (response.pseudo) {
                                if (response.pseudo !== this.currentUser.pseudo) {
                                    return { emailIsUnique: true };
                                } else {
                                    return false;
                                }
                                
                            } else {
                                return false;
                            }
                        } else {
                            return;
                        }
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );

}

}
